import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getStaticPagesConfig() {
    return {
        custom: {},
        palette: {
            [PALETTE_IDS.STATIC_PAGES]: {
                STATIC_PAGE_BG: COLOR.BRAND.BACKGROUND1,
            },
            [PALETTE_IDS.NATIVE_HOSTING_PAGE]: {
                NATIVE_HOSTING_PAGE_BG: COLOR.BRAND.BACKGROUND1,
                NATIVE_HOSTING_PAGE_HEADER_CLIENT_NAME_TEXT: COLOR.BRAND.ACCENT1,
                NATIVE_HOSTING_PAGE_STEP_INDEX_TEXT: COLOR.BRAND.BACKGROUND1,
                NATIVE_HOSTING_PAGE_STEP_INDEX_BG: COLOR.BRAND.BACKGROUND6,
            },
            [PALETTE_IDS.BAD_ROUTE_PAGE]: {
                BAD_ROUTE_PAGE_TITLE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                BAD_ROUTE_PAGE_INFO_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
            },
        },
    };
}
