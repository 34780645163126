import MojitoCore from 'mojito/core';
import MyAccountPageImpl from './my-account-page.jsx';

const UIPage = MojitoCore.Presentation.UIPage;

/**
 * @namespace MyAccountPage
 * @memberof Mojito.Applications.Sports.Pages
 */

/**
 * The My Account page.
 *
 * @class MyAccountPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Applications.Sports.Pages.MyAccountPage
 */

export default UIPage('MyAccountPage', MyAccountPageImpl);
