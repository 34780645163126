/**
 * Accept T&C info.
 *
 * @class AcceptTermsAndConditionsInfo
 * @memberof Mojito.Services.Authentication
 */
export default class AcceptTermsAndConditionsInfo {
    constructor(contentSource, content, clientData) {
        this.contentSource = contentSource;
        this.content = content;
        this.clientData = clientData;
        this.cbResolve = () => {};
        this.cbReject = () => {};
    }
}

AcceptTermsAndConditionsInfo.CONTENT_SOURCE = {
    URL: 'URL',
};
