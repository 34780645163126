import PropTypes from 'prop-types';
import MenuRowImpl from './menu-row.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * View to visualize a menu bar.
 *
 * @class MenuRow
 * @memberof Mojito.Presentation.Components
 */

/**
 * @typedef MenuRowItem
 * @type {object}
 *
 * @property {string|number} id - The id of the item.
 * @property {string|object} data - Data that will be rendered. Should be a string if the itemRenderer props is not provided.
 * @property {string} [icon] - Icon source.
 * @property {string} [altText] - Alt text set on icon image.
 * @property {string} [className] - The class to add to the DOM element rendered.
 * @property {string} [hrefLink] - A url if this item should provide links for SEO.
 * @property {object} [onClickIntent] - Intent object to be published on item click.
 * @property {string} [hash] - Used to help compare menu row items. Good for when content changes but no other data in component prop changes.
 *
 * @memberof Mojito.Presentation.Components.MenuRow
 */

/**
 * MenuRow prop types.
 *
 * @property {Mojito.Presentation.Components.MenuRow.MenuRowItem[]} menuItems - The items to be rendered.
 * @property {string|number} [selectedItemId] - The id of the selected item.
 * @property {Function} [itemRenderer] - Item renderer function. Will be used to render menu items if specified.
 * @property {Function} cbItemClicked - The callback function called when a menu item is clicked.
 * @property {object | Function} [elementRef] - A ref to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.MenuRow
 */
const propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
            icon: PropTypes.string,
            className: PropTypes.string,
            hrefLink: PropTypes.string,
            onClickIntent: PropTypes.object,
            altText: PropTypes.string,
        })
    ).isRequired,
    selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemRenderer: PropTypes.func,
    cbItemClicked: PropTypes.func.isRequired,
    elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default UIView('MenuRow', MenuRowImpl, propTypes);
