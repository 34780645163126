export const COLOR = {
    BASE: {
        PAGE: '#05325d',
        DIVIDER: '#000000',
        DIVIDER2: '#222222',
        SCRIM_LIGHT: 'rgba(0, 0, 0, 0.6)',
        SCRIM_DARK: 'rgba(0, 0, 0, 0.8)',
        SHADOW_SHORT: 'rgba(0, 0, 0, 0.3)',
        SHADOW_LONG: 'rgba(0, 0, 0, 0.5)',
    },
    BRAND: {
        BACKGROUND1: '#01204b',
        BACKGROUND2: '#ffffff',
        BACKGROUND3: '#214661',
        BACKGROUND4: '#01204b',
        BACKGROUND5: '#214661',
        BACKGROUND6: '#172538',
        BACKGROUND7: '#172538',
        BACKGROUND8: '#434f5b',
        FOREGROUND1: '#ffffff',
        FOREGROUND2: '#dddddd',
        FOREGROUND3: '#c9ccd1',
        FOREGROUND4: '#f7f7f7',
        FOREGROUND5: '#ffffff',
        FOREGROUND6: '#ffffff',
        FOREGROUND7: '#222222',
        ACCENT1: '#86f04b',
        ACCENT2: '#86f04b',
        ACCENT3: '#3e50f4',
        CTA1: '#86f04b',
        CTA2: '#3e50f4',
    },
    SEMANTIC: {
        ERROR1: '#fb4444',
        ERROR2: '#ffd8d8',
        WARNING1: '#fbe044',
        WARNING2: '#fdf3b7',
        SUCCESS1: '#00ba91',
        SUCCESS2: '#bfebe1',
    },
    RESERVED: {
        BLACK: '#2d2d2e',
        WHITE: '#f9f9f9',
        TRANSPARENT: 'rgba(249, 249, 249, 0)',
        ACCENT: '#5990e2',
    },
};

export const FONT = {
    FAMILY: 'ZurichBT, sans-serif',

    WEIGHT_NORMAL: 300,
    WEIGHT_SEMI_BOLD: 400,
    WEIGHT_BOLD: 400,

    SIZE_XXSMALL: 12,
    SIZE_XSMALL: 12,
    SIZE_SMALL: 13,
    SIZE_MEDIUM: 14,
    SIZE_LARGE: 16,
    SIZE_XLARGE: 18,
};

export const BASIC_PALETTE = {
    FONT_FAMILY: 'ZurichBT, sans-serif',

    FONT_BODY_MEDIUM: {
        fontSize: 14,
        fontWeight: 400,
    },

    DEFAULT_PAGE_BG: COLOR.BASE.PAGE,
    DEFAULT_CONTAINER_BG: COLOR.BRAND.BACKGROUND1,
    DEFAULT_HEADER_BG: COLOR.BRAND.BACKGROUND6,
    DEFAULT_ODDS_BG: COLOR.BRAND.BACKGROUND5,
    DEFAULT_ODDS_SELECTED_BG: COLOR.BRAND.ACCENT3,
    DEFAULT_PROMO_CARD_BG: COLOR.BRAND.BACKGROUND1,
    DEFAULT_SHEET_BG: COLOR.BRAND.BACKGROUND4,
    DEFAULT_POPOVER_BG: COLOR.BRAND.BACKGROUND5,
    DEFAULT_SELECTION_BG: COLOR.BRAND.ACCENT2,
    DEFAULT_STYLIZED_BG: COLOR.BRAND.BACKGROUND7,

    DEFAULT_PRIMARY_TEXT: `${COLOR.BRAND.FOREGROUND1}|${COLOR.BRAND.BACKGROUND1}`,
    DEFAULT_SECONDARY_TEXT: COLOR.BRAND.FOREGROUND2,

    DEFAULT_LIVE_COLOR: COLOR.BRAND.CTA2,
    DEFAULT_PRIMARY_BUTTON_COLOR: COLOR.BRAND.CTA1,
    DEFAULT_GENERIC_ACCENT_COLOR: COLOR.BRAND.CTA2,

    DEFAULT_CONTROLS_BORDER_COLOR: `${COLOR.BRAND.FOREGROUND1}|${COLOR.BRAND.BACKGROUND1}`,
    DEFAULT_DIVIDER: COLOR.BASE.DIVIDER,

    DEFAULT_BUTTON_BORDER_RADIUS: 4,

    DEFAULT_LEAGUE_HEADER_BG: COLOR.BRAND.BACKGROUND3,
};
