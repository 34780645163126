import { useCallback, useMemo, memo } from 'react';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import BannerCard from 'modules/event-cards/banner/index.jsx';
import MatchCard from 'modules/event-cards/match/index.jsx';
import OutrightCard from 'modules/event-cards/outright/index.jsx';
import FallbackCard from 'modules/event-cards/fallback/index.jsx';
import SelectionCard from 'modules/event-cards/selection/index.jsx';
import { useLoadingStatusMetric } from 'modules/performance/hooks';
import { useCardsPromotions } from './hooks';
import {
    usePromotions,
    usePromotionsPreload,
    usePromotionsLoadDone,
} from 'modules/promotions/hooks';
import EventCardsCarouselUtils from './utils.js';
import { isEmpty } from 'mojito/utils';
import EventCardsSkeleton from 'modules/event-cards/skeleton/index.jsx';
import EventCardsTypes from 'modules/event-cards/types.js';

const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const { Swiper, LoaderSuspense, EventCards, FlexPane } = MojitoPresentation.Components;
const { CardLoader } = EventCards;
const { FALLBACK, MATCH, BANNER, OUTRIGHT, SELECTION } = EventCardsTypes.CARD_TYPE;
const { INPLAY, SPORT } = EventCardsTypes.FALLBACK_CONTENT_TYPE;
const { enrichWithFallbackCards } = EventCardsCarouselUtils;

const createFallbackCard = type => ({
    id: type,
    type: FALLBACK,
    content: { type },
});

const fallbackCards = [INPLAY, SPORT].map(createFallbackCard);

const analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('EventCards').build();

const EventCardsCarousel = function (props) {
    const {
        mojitoTools: { config, appContext, instanceId },
        pageId,
        sportId,
    } = props;

    const { emitAnalytics } = appContext.analyticsEmitter;

    const { locationId, minCardsNumber, reportLoadingStatus } = config;

    const onSlideChange = useCallback(
        () => emitAnalytics('eventCardsCarouselSwiped'),
        [emitAnalytics]
    );

    const cardPromotions = usePromotions(locationId, pageId);
    const cards = useCardsPromotions(cardPromotions);

    const isContentLoaded = usePromotionsLoadDone([locationId]);

    const [preloadDone, onPrepareSlidesToRender] = usePromotionsPreload(
        cardPromotions,
        instanceId,
        isContentLoaded
    );

    const cardRenderersByType = useMemo(
        () => createCardRenderer(sportId, preloadDone, config),
        [sportId, preloadDone, config]
    );

    const cardsToRender = enrichWithFallbackCards(cards, minCardsNumber, fallbackCards);

    const noContent = isContentLoaded && isEmpty(cardsToRender);

    const performanceElementRef = useLoadingStatusMetric(
        isContentLoaded || noContent,
        reportLoadingStatus ? instanceId : undefined,
        'EventCardsCarousel'
    );

    if (noContent) {
        return null;
    }

    const skeleton = (
        <EventCardsSkeleton config={config.eventCardsSkeleton} elementRef={performanceElementRef} />
    );

    return (
        <FlexPane config={config.container}>
            <LoaderSuspense
                config={config.skeletonLoader}
                isContentPending={!isContentLoaded}
                loader={skeleton}
            >
                {!isEmpty(cardsToRender) ? (
                    <AnalyticsContextExtender value={analyticsContext}>
                        {
                            <Swiper
                                config={config.swiper}
                                elementRef={performanceElementRef}
                                onSlideChange={onSlideChange}
                                onPrepareSlidesToRender={onPrepareSlidesToRender}
                            >
                                {cardsToRender
                                    // eslint-disable-next-line react/display-name
                                    .map(card => readyToLoad => (
                                        <CardLoader
                                            key={card.id}
                                            readyToLoad={readyToLoad}
                                            card={card}
                                            renderer={cardRenderersByType[card.type]}
                                        />
                                    ))}
                            </Swiper>
                        }
                    </AnalyticsContextExtender>
                ) : null}
            </LoaderSuspense>
        </FlexPane>
    );
};

const createCardRenderer = (sportId, dataPreloadDone, config) => {
    const renderBannerCard = ({ id, content, altText, type }) => {
        const alternativeText = typeof altText !== 'undefined' ? altText : type;
        return (
            <BannerCard
                key={id}
                imgUrl={content.imgUrl}
                targetUrl={content.url}
                openInNewTab={content.openInNewTab}
                altText={alternativeText}
            />
        );
    };

    const renderFallbackCard = ({ id, content }) => {
        return (
            <FallbackCard
                key={id}
                type={content.type}
                sportId={sportId ?? config.fallbackSportId}
            />
        );
    };

    const renderOutrightCard = ({ id, content }) => {
        return (
            <OutrightCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                shouldRequestData={dataPreloadDone}
                reportLoadingStatus={config.reportLoadingStatus}
            />
        );
    };

    const renderSelectionCard = ({ id, content }) => {
        return (
            <SelectionCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                selectionId={content.selectionId}
                marketName={content.marketName}
                selectionName={content.selectionName}
                shouldRequestData={dataPreloadDone}
                reportLoadingStatus={config.reportLoadingStatus}
            />
        );
    };

    const renderMatchCard = ({ id, content }) => {
        return (
            <MatchCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                marketName={content.marketName}
                gameLine={content.gameLine}
                marketLines={content.marketLines}
                shouldRequestData={dataPreloadDone}
                reportLoadingStatus={config.reportLoadingStatus}
            />
        );
    };
    return {
        [MATCH]: renderMatchCard,
        [OUTRIGHT]: renderOutrightCard,
        [BANNER]: renderBannerCard,
        [FALLBACK]: renderFallbackCard,
        [SELECTION]: renderSelectionCard,
        ...config.cardRenderersByType,
    };
};

export default memo(EventCardsCarousel);
