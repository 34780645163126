import MojitoServices from 'mojito/services';

import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

const {types: BetsTypes} = MojitoServices.Bets;

export function getBetHistoryConfig() {
    const CUSTOM_CONFIG = {
        BetHistoryView: {
            filters: {
                excludedStatusTypes: [BetsTypes.BET_STATUS.PLACED],
            },
        },
    };

    return {
        custom: {
            [PALETTE_IDS.BET_HISTORY]: CUSTOM_CONFIG,
        },
        palette: {
            [PALETTE_IDS.BET_HISTORY]: {
                BET_HISTORY_DETAILS_RECEIPT_INFO_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_DETAILS_LEG_DATE_INFO_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_DETAILS_BET_BASE_INFO: {
                    BET_EVENT_NAME_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },
                BET_HISTORY_ERROR_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_MOBILE_ERROR_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BET_HISTORY_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },
            },
        },
    };
}
