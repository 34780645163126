import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import ButtonBar from 'presentation/components/button-bar/index.jsx';
import ExpandableView from 'presentation/components/expandableview/index.jsx';
import RaceMeetingCoupon from 'presentation/components/race-meeting-coupon/index.jsx';
import RaceExplanationPane from 'presentation/components/race-explanation-pane/index.jsx';
import CountryIcon from 'presentation/components/country-icon/index.jsx';

const CollapsibleMeetingPane = props => {
    const {
        sportId,
        countryCode,
        countryName,
        title,
        initiallyExpanded,
        filters,
        selectedFilter,
        filterClickedCb,
        mojitoTools,
    } = props;
    const { config } = mojitoTools;
    const onTabSelectionClick = selectionId => filterClickedCb(selectionId);

    const renderHeader = () => (
        <>
            {config.shouldShowFlag && (
                <CountryIcon
                    countryCode={countryCode}
                    countryName={countryName}
                    sportId={sportId}
                    config={config.countryIcon}
                />
            )}
            <Text config={config.title}>{title}</Text>
        </>
    );

    return (
        <FlexPane config={config.container}>
            <ExpandableView
                config={config.expandableView}
                expanded={initiallyExpanded}
                headerRenderer={renderHeader}
            >
                <FlexPane config={config.contentContainer}>
                    {filters && (
                        <ButtonBar
                            items={filters}
                            selectedKey={selectedFilter}
                            onSelectionChanged={onTabSelectionClick}
                            config={config.locationsButtonBar}
                        />
                    )}
                    <Meetings {...props} />
                </FlexPane>
            </ExpandableView>
        </FlexPane>
    );
};

const Meetings = props => {
    const {
        allowBestOddsGuaranteed,
        meetings,
        mojitoTools: { config },
    } = props;

    const meetingContent = meetings.map(meeting => (
        <MeetingCoupon
            {...props}
            key={meeting.id}
            meeting={meeting}
            allowBestOddsGuaranteed={allowBestOddsGuaranteed}
        />
    ));

    return (
        <FlexPane config={config.meetingsContainer}>
            <FlexPane config={config.meetingContentContainer}>{meetingContent}</FlexPane>
            <RaceExplanationPane
                config={config.raceExplanationPane}
                showBestOddsGuaranteed={allowBestOddsGuaranteed}
            />
        </FlexPane>
    );
};

const MeetingCoupon = ({
    meeting,
    allowBestOddsGuaranteed,
    onRaceClicked,
    mojitoTools: { config, stringResolver },
}) => {
    const { races, streamAvailable, raceTrackStatus } = meeting;

    let trackStatus;
    if (races.length) {
        const trackCondition = (raceTrackStatus.trackCondition || '').trim();
        if (trackCondition) {
            trackStatus = `${stringResolver.resolveString(
                '$COLLAPSIBLE_MEETING_PANE.TRACK_CONDITION_PREFIX'
            )}${trackCondition}`;
        }
    }

    return (
        <RaceMeetingCoupon
            meetingName={meeting.name}
            meetingId={meeting.id}
            races={races}
            cbRaceClicked={onRaceClicked}
            trackStatus={trackStatus}
            streamAvailable={streamAvailable}
            allowBestOddsGuaranteed={allowBestOddsGuaranteed}
            config={config.raceMeetingCoupon}
        />
    );
};

export default CollapsibleMeetingPane;
