import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getMenuListsConfigs() {
    return {
        custom: {
            [PALETTE_IDS.AZ_MENU]: {
                // This fixes global BG set for LinkMenu
                // TODO: remove when MENU tile will be removed (together with global LinkMenu styles)
                AzOverview: {
                    informationSectionContainer: {
                        regularItem: {
                            selected: {
                                style: {
                                    base: {
                                        backgroundColor: 'transparent',
                                    },
                                },
                            },
                            unselected: {
                                style: {
                                    base: {
                                        backgroundColor: 'transparent',
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.MENUS_LINKS_LISTS]: {
                LHM_HEADER_BG: COLOR.BRAND.BACKGROUND6,
                LHM_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_BOLD,
                    textTransform: 'uppercase',
                },
            },
            [PALETTE_IDS.MINI_EVENT_ITEM]: {
                MINI_EVENT_ITEM_BG: COLOR.BRAND.BACKGROUND1,
                MINI_EVENT_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                MINI_EVENT_ITEM_SELECTED_CONTENT: {
                    MINI_EVENT_ITEM_TIMING_STATUS: {
                        EVENT_TIMING_LIVE_TEXT: {color: COLOR.BRAND.FOREGROUND4, fontWeight: FONT.WEIGHT_NORMAL},
                        EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.FOREGROUND4,
                        EVENT_TIMING_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND4,
                        STREAMING_INDICATOR: {
                            INDICATOR_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                        },
                    },
                    MINI_EVENT_ITEM_SCOREBOARD: {
                        MINI_SCOREBOARD_SCORES_TEXT: COLOR.BRAND.BACKGROUND1,
                        MINI_SCOREBOARD_SCORE_COUNT_TEXT: COLOR.BRAND.FOREGROUND1,
                        MINI_SCOREBOARD_SERVER_INDICATOR: COLOR.BRAND.BACKGROUND1,
                        MINI_SCOREBOARD_SEGMENT_INDICATOR_TEXT: {
                            color: COLOR.BRAND.BACKGROUND1,
                            fontWeight: FONT.WEIGHT_NORMAL,
                        },
                    },
                    MINI_EVENT_ITEM_PARTICIPANTS: {
                        PARTICIPANTS_TEXT: {
                            color: COLOR.BRAND.FOREGROUND5,
                            fontWeight: FONT.WEIGHT_SEMI_BOLD,
                        },
                    },
                    MINI_EVENT_ITEM_CHEVRON_ICON_COLOR: COLOR.BRAND.FOREGROUND6,
                },
                MINI_EVENT_ITEM_CONTENT: {
                    MINI_EVENT_ITEM_TIMING_STATUS: {
                        EVENT_TIMING_LIVE_TEXT: {
                            color: COLOR.BRAND.ACCENT2,
                            fontWeight: FONT.WEIGHT_NORMAL,
                        },
                    },
                    MINI_EVENT_ITEM_SCOREBOARD: {
                        MINI_SCOREBOARD_SEGMENT_INDICATOR_TEXT: {
                            color: COLOR.BRAND.FOREGROUND2,
                            fontWeight: FONT.WEIGHT_NORMAL,
                        },
                    },
                },
            },
            [PALETTE_IDS.QUICK_NAVIGATIONS]: {
                LEAGUES_MINI_EVENT_ITEM: {
                    MINI_EVENT_ITEM_CONTENT: {
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: COLOR.BRAND.FOREGROUND1,
                        },
                    },
                    MINI_EVENT_ITEM_SELECTED_CONTENT: {
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: COLOR.BRAND.FOREGROUND5,
                        },
                    },
                },
            },
            [PALETTE_IDS.LIST_ITEM]: {
                LIST_ITEM_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                LIST_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                LIST_ITEM_SELECTED_TEXT: COLOR.BRAND.FOREGROUND5,
            },
            [PALETTE_IDS.INPLAY_MENU]: {
                DESKTOP_INPLAY_MENU_SPORT_ITEM_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_NORMAL,
                    textTransform: 'uppercase',
                },
                DESKTOP_INPLAY_MENU_EVENT_GROUP_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND4,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.AZ_MENU]: {
                DESKTOP_AZ_HEADER_BG: COLOR.BRAND.BACKGROUND6,
                DESKTOP_AZ_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    textTransform: 'uppercase',
                },
                DESKTOP_AZ_FAVORITE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                MOBILE_AZ_FAVORITE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                MOBILE_AZ_SECTION_HEADER_TEXT: {
                    fontSize: FONT.SIZE_MEDIUM,
                    color: COLOR.BRAND.FOREGROUND2,
                    textTransform: 'uppercase',
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                },
                MOBILE_AZ_ALL_SPORTS_BUTTON: {
                    TERTIARY_BUTTON_TEXT: {color: COLOR.BRAND.FOREGROUND1, textTransform: 'capitalize'},
                },
                MOBILE_AZ_BACK_BUTTON_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_SEMI_BOLD},
            },
            [PALETTE_IDS.DESKTOP_INFO_SECTION]: {
                INFO_SECTION_HEADER_BG: COLOR.BRAND.BACKGROUND6,
            },
            [PALETTE_IDS.QUICK_NAVIGATIONS]: {
                EVENTS_MINI_EVENT_ITEM: {
                    MINI_EVENT_ITEM_BG: COLOR.BRAND.BACKGROUND4,
                    MINI_EVENT_ITEM_CONTENT: {
                        MINI_EVENT_ITEM_TIMING_STATUS: {
                            EVENT_TIMING_UPCOMING_TEXT: {
                                color: COLOR.BRAND.ACCENT1,
                                fontWeight: FONT.WEIGHT_NORMAL,
                            },
                        },
                    },
                    MINI_EVENT_ITEM_SELECTED_CONTENT: {
                        MINI_EVENT_ITEM_TIMING_STATUS: {
                            EVENT_TIMING_UPCOMING_TEXT: {
                                color: COLOR.BRAND.FOREGROUND1,
                                fontWeight: FONT.WEIGHT_NORMAL,
                            },
                        },
                    },
                },
                LEAGUES_MINI_EVENT_ITEM: {
                    MINI_EVENT_ITEM_BG: COLOR.BRAND.BACKGROUND4,
                    MINI_EVENT_ITEM_CONTENT: {
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: {
                                fontWeight: FONT.WEIGHT_NORMAL,
                            },
                        },
                    },
                    MINI_EVENT_ITEM_SELECTED_CONTENT: {
                        MINI_EVENT_ITEM_PARTICIPANTS: {
                            PARTICIPANTS_TEXT: {
                                fontWeight: FONT.WEIGHT_NORMAL,
                            },
                        },
                    },
                },
            },
        },
    };
}
