import MojitoCore from 'mojito/core';
import { noop, isEmpty, merge } from 'mojito/utils';
import AbstractAuthenticationService from './abstract-authentication-service.js';
import AcceptTermsAndConditionsInfo from 'services/authentication/accept-terms-and-conditions-info.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import AuthenticationTypes from 'services/authentication/types.js';
import { TokenCredentialsBuilder } from 'services/authentication/credentials';
import MessagingTypes from 'services/messaging/types.js';

const log = MojitoCore.logger.get('IMSAuthenticationService');
const { deviceTypeGuesser } = MojitoCore.Base;
const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { CREDENTIALS_PUBLIC_TYPE, CREDENTIALS_PRIVATE_TYPE, LOGIN_ERRORS } = AuthenticationTypes;
const { INCOMING_MESSAGES } = MessagingTypes;
const { dispatch } = MojitoCore.Services.redux.store;

const IMS_SETUP_RETRY_MS = 200;
const IMS_DEVICE_FAMILY = {
    MOBILE: 'Smartphone',
    DESKTOP: 'Desktop',
};

const changePasswordErrors = {
    [CREDENTIALS_PUBLIC_TYPE.USERNAME]: {
        type: LOGIN_ERRORS.CHANGE_PASSWORD_IS_REQUIRED,
        changeDataRetriever: response =>
            response.sessionValidationData.SessionValidationByPasswordChangeData,
        policyRetriever: changeData => Number(changeData[0].passwordPolicy),
    },
    [CREDENTIALS_PUBLIC_TYPE.CARD]: {
        type: LOGIN_ERRORS.CHANGE_PIN_IS_REQUIRED,
        changeDataRetriever: response =>
            response.sessionValidationData.SessionValidationByPinChangeData,
        policyRetriever: changeData => Number(changeData[0].pinPolicy),
    },
};

const timeStampRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

/**
 *
 * Class wrapping the IMS PAS API supporting SSO login.
 *
 * @class IMSAuthenticationService
 * @memberof Mojito.Services.Authentication
 */
class IMSAuthenticationService extends AbstractAuthenticationService {
    constructor() {
        super();

        this.isInitialized = false;
        this.imsConfig = undefined;
        this.isKeepAlivePolling = false;
        this.getTemporaryTokenListeners = [];

        this.getTemporaryTokenCallout = this.getTemporaryTokenCallout.bind(this);
    }

    /**
     * Tries to do an initial setup.
     * Potentially fails due to the IMS scripts not being loaded yet.
     * If that is the case, it will try to re-init within 200ms interval.
     *
     * @param {object} imsConfig - IMS configuration.
     * @param {Mojito.Services.Authentication.AbstractAuthenticationService} authService - Authentication service to back this IMS service.
     * @param {string} languageCode - Language code.
     * @param {Function} successCallback - Success callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#configure
     */
    configure(imsConfig, authService, languageCode, successCallback) {
        this.imsConfig = imsConfig;
        this.authService = authService;
        this.languageCode = languageCode;
        this._init(successCallback);
    }

    /**
     * Reset service to initial state.
     *
     * @function Mojito.Services.Authentication.IMSAuthenticationService#terminate
     */
    terminate() {
        this.isInitialized = false;
        this.imsConfig = undefined;
        this.isKeepAlivePolling = false;
        this.getTemporaryTokenListeners = [];
        this.authService = undefined;
        this.languageCode = undefined;
    }

    /**
     * Initializes the IMS library if available.
     *
     * @private
     * @param {Function} successCallback - Success callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#_init
     */
    _init(successCallback) {
        // Do not initialize again
        if (this.isInitialized) {
            if (successCallback) {
                successCallback();
            }
            return;
        }

        const trySetupIms = () => {
            if (!window.iapiConf || !window.iapiSetCallout) {
                // API not available? Try again in a while.
                setTimeout(trySetupIms, IMS_SETUP_RETRY_MS);
                return;
            }

            // Remove the potential index.html
            let redirectUrl = window.location.pathname.replace('index.html', '');

            // Add the pas file handling the redirect
            redirectUrl += 'pas.html';

            window.iapiConf['redirectUrl'] = redirectUrl;

            // Set configured system id if any
            if (window.iapiSetSystemId && this.imsConfig.systemId) {
                window.iapiSetSystemId(this.imsConfig.systemId);
            }

            // This is a sportsbook!
            window.iapiSetClientType('sportsbook');
            window.iapiSetClientPlatform('web');
            window.iapiSetDeliveryPlatform('HTML5');
            window.iapiSetServiceType(this.imsConfig.serviceType);
            window.iapiSetDeviceFamily(
                deviceTypeGuesser.isProbablyHandHeldDevice()
                    ? IMS_DEVICE_FAMILY.MOBILE
                    : IMS_DEVICE_FAMILY.DESKTOP
            );
            window.iapiSetCallout('GetTemporaryAuthenticationToken', this.getTemporaryTokenCallout);

            this.isInitialized = true;
            log.info('IMS initialized!');

            if (successCallback) {
                successCallback();
            }
        };

        if (this.imsConfig) {
            trySetupIms();
        } else {
            log.info('No IMS config found, will not try to initialize');
        }
    }

    /**
     * Invalidates user session.
     *
     * @function Mojito.Services.Authentication.IMSAuthenticationService#invalidateSession
     */
    invalidateSession() {
        dispatch(authenticationActions.logout());
    }

    /**
     * Tries to restore IMS user session by
     * checking if already authenticated by SSO or has valid user session.
     * If success - do login toward betting platform service implementation,
     * see {@link Mojito.Services.Authentication.AbstractAuthenticationService#login|authentication service login}.
     *
     * @param {Function} successCallback - Success callback.
     * @param {Function} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#restoreSession
     */
    restoreSession(successCallback, errorCallback) {
        // Sanity
        if (!this.isInitialized) {
            log.warn('IMS is not initialized.');
            return;
        }

        // Do initial check for logged in user
        this._checkIfAlreadyLoggedIn(
            // Success callback
            (username, token) => {
                log.info('Already logged in via IMS!');

                this._doLoginWithToken(username, token, CREDENTIALS_PUBLIC_TYPE.USERNAME)
                    .then(successCallback)
                    .catch(() => {
                        log.warn('Failed to log in with token!');
                        errorCallback();
                    });
            },
            // Error callback
            () => {
                log.warn('Failed to check if already logged in');
                errorCallback();
            }
        );
    }

    getTemporaryTokenCallout(response) {
        if (this.getTemporaryTokenListeners.length) {
            log.info('GetTemporaryAuthenticationToken response: ', response);
            const listener = this.getTemporaryTokenListeners.pop();
            listener(response);
        } else {
            log.error(
                'Got response for temporary token callout, but can not find any listener requested it',
                response
            );
        }
    }

    /**
     * Retrieves temporary authentication token that is typically
     * used for cross system authentication towards IMS.
     *
     * @returns {Promise} A promise of token fetch with the resulting token or error code.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#getTemporaryToken
     */
    getTemporaryToken() {
        return new Promise((resolve, reject) => {
            this.getTemporaryTokenListeners.unshift(response => {
                if (response.errorCode === 0) {
                    resolve(response.sessionToken.sessionToken);
                } else {
                    const type = this._mapLoginErrorTypes(response.errorCode);
                    reject({ type });
                }
            });

            // Get the temp token
            window.iapiRequestTemporaryToken(1);
        });
    }

    /**
     * Performs an IMS login using the specified user name and password.
     *
     * @param {Mojito.Services.Authentication.types.AuthCredentials} credentials - User authentication credentials.
     * @param {Mojito.Services.Authentication.types.loginSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.loginFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#login
     */
    login(credentials, successCallback, errorCallback) {
        // Sanity
        if (!this.isInitialized) {
            log.warn('IMS is not initialized.');
            return;
        }

        const { publicFactor, privateFactor } = credentials.data;
        const { loginMethodDetector } = this.imsConfig;
        let loginMethod = loginMethodDetector
            ? loginMethodDetector(credentials)
            : credentials.publicType;

        // Prepare for the response
        window.iapiSetCallout('Login', (response, loginData) => {
            log.info('Login response: ', response, ' loginData: ', loginData);

            if (response.errorCode !== 0) {
                const actions = response.actions;

                // If we receive error code 48, the playerActionShowMessages array will always contain one item
                // except when getting an OASIS regulation error, then it will contain two items.
                // In that case, the second item of the playerActionShowMessages array should be ignored.
                const playerActionShowMessages = (actions && actions.PlayerActionShowMessage) || [];
                const playerActionShowLoginMessages =
                    (actions && actions.PlayerActionShowLoginMessage) || [];

                let playerActionMessages = [];
                if (response.errorCode === 48) {
                    playerActionMessages.push(playerActionShowMessages[0].message);
                } else {
                    playerActionMessages = playerActionShowMessages
                        .concat(playerActionShowLoginMessages)
                        .map(elem => elem.message)
                        .filter(Boolean);
                }
                const errorType = playerActionMessages.length
                    ? LOGIN_ERRORS.IMS_ERROR
                    : this._mapLoginErrorTypes(response.errorCode);

                errorCallback({ type: errorType, messages: playerActionMessages });

                return;
            }

            // Check for change password or pin error.
            const changePasswordOrPinError = this._getChangePasswordOrPinError(
                response,
                loginMethod
            );

            if (changePasswordOrPinError) {
                errorCallback(changePasswordOrPinError);
                return;
            }
            let loginUsername = publicFactor;
            // If IMS responds with a username, use that instead of the potential Card number the user logged into IMS with.
            if (response.username && response.username.username) {
                loginUsername = response.username.username;
                loginMethod = CREDENTIALS_PUBLIC_TYPE.USERNAME;
            }

            this._ensureTermsAndConditionsAccepted(response, errorCallback)
                .then(() => {
                    // Get the last login time
                    window.iapiSetCallout('GetWaitingMessages', resp => {
                        const lastLoginTime = this.getLastLoginTime(resp);
                        log.info('Last login time: ', lastLoginTime);
                        if (lastLoginTime) {
                            dispatch(
                                authenticationActions.loginMessage({
                                    type: INCOMING_MESSAGES.SHOW_LAST_LOGIN_ALERT,
                                    payload: {
                                        additionalInfo: { userName: loginUsername, lastLoginTime },
                                    },
                                })
                            );
                        }
                    });

                    window.iapiGetWaitingMessages('login', 1);

                    // Get the IMS login response messages
                    this._dispatchLoginResponseMessages(response);

                    // Get the temporary token
                    return this.getTemporaryToken();
                })
                .then(token =>
                    this._doLoginWithToken(
                        loginUsername,
                        token,
                        loginMethod,
                        new Date().toISOString()
                    )
                )
                .then(successCallback)
                .catch(error => {
                    log.warn('IMS login error:', error);
                    errorCallback(error);
                });
        });

        if (loginMethod === CREDENTIALS_PUBLIC_TYPE.USERNAME) {
            window.iapiLogin(publicFactor, privateFactor, 1, this.languageCode);
        } else if (loginMethod === CREDENTIALS_PUBLIC_TYPE.CARD) {
            window.iapiLoginPrintedIdTokenCodeAndPin(
                publicFactor,
                privateFactor,
                this.languageCode
            );
        }
    }

    /**
     * Performs IMS logout.
     *
     * @param {Mojito.Services.Authentication.types.logoutSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.logoutFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#logout
     */
    logout(successCallback, errorCallback) {
        // Sanity
        if (!this.isInitialized) {
            log.warn('IMS is not initialized.');
            return;
        }

        this.authService.logout(() => {
            window.iapiSetCallout('Logout', response => {
                if (response.errorCode === 0) {
                    log.info('Logged out of IMS!');

                    this.isKeepAlivePolling = false;

                    if (successCallback) {
                        successCallback();
                    }
                } else if (errorCallback) {
                    errorCallback();
                }
            });

            window.iapiLogout(1, 1);
        }, errorCallback);
    }

    /**
     * Validates existing IMS user session.
     *
     * @param {Mojito.Services.Authentication.types.validateSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.validateSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#validateSession
     */
    validateSession(successCallback, errorCallback) {
        window.iapiKeepAlive(1);
        this.authService.validateSession(successCallback, errorCallback);
    }

    /**
     * Change the user password.
     *
     * @param {Mojito.Services.Authentication.types.PasswordUpdate} passwordUpdate - Object defines password change data.
     * @param {Mojito.Services.Authentication.types.changePasswordSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.changePasswordFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#changePassword
     */
    changePassword(passwordUpdate, successCallback, errorCallback) {
        const { currentPassword, newPassword, type: passwordType } = passwordUpdate;
        if (!currentPassword || !newPassword) {
            log.warn('One or both passwords missing');
            return;
        }

        window.iapiSetCallout('ValidateLoginSession', response => {
            const success = successCallback || noop;
            const error = errorCallback || noop;
            response.errorCode === 0 ? success() : error();
        });

        if (passwordType === CREDENTIALS_PRIVATE_TYPE.PIN) {
            window.iapiValidatePinChange(currentPassword, newPassword);
        } else {
            window.iapiValidatePasswordChange(currentPassword, newPassword, 1, 1);
        }
    }

    /**
     * Accept terms and conditions.
     *
     * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C info associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
     * @param {Mojito.Services.Authentication.types.acceptTermAndConditionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.acceptTermAndConditionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#acceptTermsAndConditions
     */
    acceptTermsAndConditions(termsAndConditionsInfo, successCallback, errorCallback) {
        this._acceptTermsAndConditions(termsAndConditionsInfo, 1, successCallback, errorCallback);
    }

    /**
     * Decline terms and conditions.
     *
     * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C info associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
     * @param {Mojito.Services.Authentication.types.declineTermAndConditionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.declineTermAndConditionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#declineTermsAndConditions
     */
    declineTermsAndConditions(termsAndConditionsInfo, successCallback, errorCallback) {
        this._acceptTermsAndConditions(termsAndConditionsInfo, 0, successCallback, errorCallback);
    }

    /**
     * Accepts / declines the specified T&C info.
     *
     * @private
     * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C info as detected in login response.
     * @param {number} accept - 1 if accepted else 0.
     * @param {Function} successCallback - Success callback.
     * @param {Function} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#_acceptTermsAndConditions
     */
    _acceptTermsAndConditions(termsAndConditionsInfo, accept, successCallback, errorCallback) {
        if (!termsAndConditionsInfo || !termsAndConditionsInfo.clientData) {
            log.warn('No T&C info available!');
            return;
        }

        const termVersionReference = termsAndConditionsInfo.clientData;

        window.iapiSetCallout('ValidateLoginSession', response => {
            if (response.errorCode === 0) {
                log.info(
                    'T&C version ',
                    termVersionReference,
                    ' was: ',
                    accept ? 'accepted' : 'declined'
                );
                if (successCallback) {
                    successCallback();
                }
            } else if (errorCallback) {
                errorCallback();
            }
        });

        window.iapiValidateTCVersion(termVersionReference, accept, 1);
    }

    /**
     * Tries to login with the specified token using the actual auth service.
     *
     * @private
     * @param {string} username - User name.
     * @param {string} token - Temporary token to perform login to third party systems, e.g., betting platform like Quantum or Geneity.
     * @param {Mojito.Services.Authentication.types.CREDENTIALS_PUBLIC_TYPE} [credentialsPublicType] - The type of public credential factor, e.g., user name, card number etc.
     * @param {string} loginTime - Login time in ISO 8601 format.
     *
     * @returns {Promise} A promise of logging in with the given session token.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#_doLoginWithToken
     */
    _doLoginWithToken(username, token, credentialsPublicType, loginTime) {
        return new Promise((resolve, reject) => {
            const credentials = new TokenCredentialsBuilder()
                .withPublicFactor(username)
                .withToken(token)
                .withPublicType(credentialsPublicType)
                .build();
            this.authService.login(
                credentials,
                authInfo => {
                    this._startKeepAlivePolling();
                    authInfo = this._ensureSessionCreationTime(authInfo, loginTime);
                    resolve(authInfo);
                },
                reject
            );
        });
    }

    /**
     * Checks if the user is already logged in.
     * To be called at application startup.
     *
     * @private
     * @param {Function} cbLoggedIn - Callback function called if the user is already logged in.
     * @param {Function} cbLoggedOut - Callback function called if the user is not already logged in, or if something went wrong.
     * @function Mojito.Services.Authentication.IMSAuthenticationService#_checkIfAlreadyLoggedIn
     */
    _checkIfAlreadyLoggedIn(cbLoggedIn, cbLoggedOut) {
        // Sanity
        if (!this.isInitialized) {
            log.warn('IMS is not initialized.');
            return;
        }

        // Prepare for the response
        window.iapiSetCallout('GetLoggedInPlayer', response => {
            log.info('GetLoggedInPlayer response: ', response);

            if (
                response.errorCode === 0 &&
                response.cookieExists &&
                response.cookieExists !== '0'
            ) {
                // Get the temporary token. We assume UserIdType is NAME since we pick the username from the response.
                this.getTemporaryToken()
                    .then(token => cbLoggedIn(response.username, token))
                    .catch(() => {
                        log.warn('Failed to get temporary token');
                        // Something is wrong - try to log out, and then unconditionally signal that we are not logged in
                        this.logout(cbLoggedOut, cbLoggedOut);
                    });
            } else {
                // Something is wrong - try to log out, and then unconditionally signal that we are not logged in
                this.logout(cbLoggedOut, cbLoggedOut);
            }
        });

        // Get logged in player info
        window.iapiGetLoggedInPlayer(1);
    }

    _ensureTermsAndConditionsAccepted(response, errorCallback) {
        return new Promise((resolve, reject) => {
            // Check for acceptance of T&C
            let tcError = this._getAcceptTermsAndConditionsError(response);
            if (tcError) {
                const additionalInfo = { cbResolve: resolve, cbReject: reject };
                tcError = merge(tcError, { additionalInfo });
                // This will cause the Accept T&C dialog to show, which will eventually resolve or reject this promise
                errorCallback(tcError);
            } else {
                resolve();
            }
        });
    }

    _getChangePasswordOrPinError(response, loginMethod) {
        if (!response.sessionValidationData) {
            return;
        }
        const { changeDataRetriever, policyRetriever, type } = changePasswordErrors[loginMethod];
        const changeData = changeDataRetriever(response);
        if (!isEmpty(changeData)) {
            return {
                type,
                additionalInfo: {
                    policy: policyRetriever(changeData),
                },
            };
        }
    }

    _getAcceptTermsAndConditionsError({ sessionValidationData }) {
        const tcAcceptData =
            sessionValidationData && sessionValidationData.SessionValidationByTCVersionData;
        if (isEmpty(tcAcceptData)) {
            return;
        }
        const additionalInfo = new AcceptTermsAndConditionsInfo(
            AcceptTermsAndConditionsInfo.CONTENT_SOURCE.URL,
            tcAcceptData[0].tcVersionUrl,
            tcAcceptData[0].termVersionReference
        );

        return {
            type: LOGIN_ERRORS.TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED,
            additionalInfo,
        };
    }

    getLastLoginTime(response) {
        if (this._isResponseValid(response)) {
            const loginMessages = response.actions.PlayerActionShowLoginMessage;
            const dateFormatRegex = this.imsConfig?.lastLoginDateTimeFormat || timeStampRegex;
            return this._findLastLoginTime(loginMessages, dateFormatRegex);
        }
    }

    _isResponseValid(response) {
        return response.errorCode === 0 && response.actions;
    }

    _findLastLoginTime(messages, dateFormatRegex) {
        for (const { message } of messages) {
            const matches = message.match(dateFormatRegex);
            if (Array.isArray(matches)) {
                return matches[0];
            }
        }
    }

    _dispatchLoginResponseMessages(response) {
        let loginMessages = [];

        if (this._isResponseValid(response)) {
            const arr = response.actions.PlayerActionShowMessage;
            if (Array.isArray(arr)) {
                loginMessages = arr.map(msg => msg.message);
            }
        }

        loginMessages.forEach(msg =>
            dispatch(
                authenticationActions.loginMessage({
                    type: INCOMING_MESSAGES.SHOW_SIMPLE_ALERT,
                    payload: { text: msg },
                })
            )
        );
    }

    _startKeepAlivePolling() {
        window.iapiSetCallout('KeepAlive', response => {
            if (response.errorCode !== 0) {
                this.invalidateSession();
            }
        });

        const keepAlivePoller = () => {
            if (this.isKeepAlivePolling) {
                window.iapiKeepAlive(1);
                setTimeout(
                    keepAlivePoller,
                    this.imsConfig.keepAliveIntervalMilliSeconds || 10 * 60 * 1000
                ); // If no polling interval provided, fallback to 10 minutes
            }
        };

        this.isKeepAlivePolling = true;
        keepAlivePoller();
    }

    _mapLoginErrorTypes(errorCode) {
        // If / When there is a requirement to show actual IMS messages we should introduce
        // a CUSTOM error code to be passed around together with the actual message
        let error;

        // Default error handling
        switch (errorCode) {
            case 2:
                error = LOGIN_ERRORS.WRONG_CREDENTIALS;
                break;

            case 22:
                error = LOGIN_ERRORS.ACCOUNT_TEMPORARILY_LOCKED;
                break;

            default:
                error = TransactionsTypes.ERROR_CODE.UNKNOWN;
                break;
        }

        return error;
    }

    _ensureSessionCreationTime(authInfo, fallbackTime) {
        if (!authInfo || !fallbackTime) {
            return authInfo;
        }
        // Check if authInfo contains session creation time.
        // Depending on backend integration it can be empty, in that case use fallbackTime as a value.
        const { creationTime } = authInfo.sessionInfo || {};
        return !creationTime
            ? merge(authInfo, { sessionInfo: { creationTime: fallbackTime } })
            : authInfo;
    }
}

export default new IMSAuthenticationService();
