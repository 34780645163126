import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR} from '../theme.js';

export function getBonusesConfig() {
    return {
        palette: {
            [PALETTE_IDS.BONUSES]: {
                BONUSES_SECTION_TITLE_TEXT: COLOR.BRAND.FOREGROUND1,
                BONUS_ITEM_TITLE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    textTransform: 'uppercase',
                },
            },
        },
    };
}
