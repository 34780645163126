import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import EventsCoupon from 'modules/events-coupon/index.jsx';
import { EventListProvider } from 'modules/event-list/context/event-list-context.jsx';

const { MenuRow, MenuRowSkeleton, LoaderSuspense, FlexPane } = MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;
const { useComponentVisibility } = MojitoPresentation.Hooks;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

export default class TopSports extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.onDataLoad = this.onDataLoad.bind(this);
        this.onEventListFooterClicked = this.onEventListFooterClicked.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    getSport(sports, sportId) {
        if (!sports || !sportId) {
            return;
        }
        return sports.find(sport => sport.id === sportId);
    }

    getTotalNumberOfEvents() {
        return this.props.topSports.reduce(
            (total, { numberOfEvents }) => total + numberOfEvents,
            0
        );
    }

    resolveSportIconUrl(sportId) {
        return this.config.sportIcons[sportId] || this.config.sportIcons.defaultIcon;
    }

    onEventListFooterClicked(sportId) {
        this.emitAnalytics('topSportShowAllClicked', {
            sportType: sportId,
            isInplay: this.config.displayEventListAsInplay,
        });
    }

    getSportText(sportId, count, useCounter, labelWithEventCount, labelWithoutEventCount) {
        const sportName = this.appContext().sportName(sportId);

        if (sportName && useCounter) {
            if (count > 0) {
                return this.resolveAndFormatString(labelWithEventCount, sportName, count);
            }
        }

        if (sportName && labelWithoutEventCount) {
            return this.resolveAndFormatString(labelWithoutEventCount, sportName);
        }

        return sportName;
    }

    getNavigationTitleText() {
        const { navigationTitle } = this.props;
        if (this.config.eventCounter.headerTotal) {
            const totalCount = this.getTotalNumberOfEvents();
            if (totalCount > 0) {
                return this.resolveAndFormatString(
                    '$TOP_SPORTS.TITLE_WITH_COUNTER',
                    navigationTitle,
                    totalCount
                );
            }
        }
        return navigationTitle;
    }

    getMenuItems() {
        const sports = this.props.topSports.slice(0, this.config.maxNumberOfSports);
        return sports
            .map(sport => {
                const { id: sportId, numberOfEvents } = sport;
                const { headerPerSport } = this.config.eventCounter;
                const sportLabel = this.getSportText(
                    sportId,
                    numberOfEvents,
                    headerPerSport,
                    '$TOP_SPORTS.SPORT_NAME_WITH_COUNTER'
                );
                if (!sportLabel) {
                    return null;
                }
                return {
                    id: sportId,
                    data: sportLabel,
                    icon:
                        (this.config.showMenuSportIcon && this.resolveSportIconUrl(sportId)) ||
                        undefined,
                    altText: this.appContext().sportName(sportId),
                };
            })
            .filter(Boolean);
    }

    onDataLoad() {
        const { emitPerformanceMark } = this.props.mojitoTools;
        emitPerformanceMark('moduleRendered', true);
    }

    renderTitle() {
        const { navigationTitle } = this.props;

        return (
            navigationTitle && (
                <div
                    style={this.style.navigationTitle}
                    className={`ta-navigationTitle-${navigationTitle}`}
                >
                    {this.getNavigationTitleText()}
                </div>
            )
        );
    }

    renderNavigation(selectedSport) {
        const { isLoading, onSportClicked } = this.props;
        const menuItems = this.getMenuItems();

        const isContentPending = menuItems.length === 0 && isLoading;
        const skeleton = <MenuRowSkeleton config={this.config.navigationMenuSkeleton} />;
        return (
            <div style={this.style.navigationHeaderContainer} className="ta-TopSportsViewNav">
                <LoaderSuspense
                    config={this.config.skeletonLoader}
                    loader={skeleton}
                    isContentPending={isContentPending}
                >
                    {menuItems.length && (
                        <FlexPane config={this.config.navigationMenuContainer}>
                            <MenuRow
                                config={this.config.navigationMenu}
                                menuItems={menuItems}
                                selectedItemId={selectedSport}
                                cbItemClicked={onSportClicked}
                            />
                        </FlexPane>
                    )}
                </LoaderSuspense>
            </div>
        );
    }

    renderContent(selectedSport) {
        const {
            topSports,
            mojitoTools: {
                appContext: { subscriptionResolver },
            },
        } = this.props;
        const sport = this.getSport(topSports, selectedSport);
        const { id: sportId } = sport || {};

        const resolverSubscriptionKey = this.config.displayEventListAsInplay
            ? subscriptionResolver.liveMatchEvents
            : subscriptionResolver.futureMatchEvents;
        const eventGroupsId = sportId ? resolverSubscriptionKey(sportId) : undefined;

        return (
            <EventListProvider value={{ numberOfEvents: sport?.numberOfEvents }}>
                <EventsCoupon
                    sportId={sportId}
                    eventGroupsId={eventGroupsId}
                    onShowAllButtonClick={this.onEventListFooterClicked}
                    config={this.config.eventsCoupon}
                    isInPlay={this.config.displayEventListAsInplay}
                    onDataLoad={this.onDataLoad}
                />
            </EventListProvider>
        );
    }

    render() {
        const { selectedSport, onShowUp } = this.props;
        return (
            <TopSportContainer config={this.config} onShowUp={onShowUp}>
                {this.renderTitle()}
                <FlexPane config={this.config.couponContainer}>
                    {this.renderNavigation(selectedSport)}
                    {this.renderContent(selectedSport)}
                </FlexPane>
            </TopSportContainer>
        );
    }
}

const TopSportContainer = props => {
    const { children, config, onShowUp } = props;
    const elementRef = useComponentVisibility(onShowUp);
    return (
        <FlexPane elementRef={elementRef} class="ta-TopSportsView" config={config.mainContainer}>
            {children}
        </FlexPane>
    );
};

TopSports.getStyle = function (config, applicationMode, merge) {
    return {
        navigationHeaderContainer: merge(
            {
                display: 'flex',
                flex: '1 1 auto',
            },
            config.style.navigationHeaderContainer
        ),
        navigationTitle: merge(
            {
                flexGrow: 1,
                whiteSpace: 'nowrap', // Make sure the menuRow use button scrolling instead of wrapping when running out of space
            },
            config.style.navigationTitle
        ),
    };
};
