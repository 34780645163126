import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import PromotionUtils from 'modules/promotions/utils/promotion-utils';
import { usePromotions, usePromotionsLoadDone } from 'modules/promotions/hooks';
import { isEmpty } from 'mojito/utils';
import { useLoadingStatusMetric } from 'modules/performance/hooks';

const { TARGET_TYPE } = MojitoServices.Promotions.types;
const { MenuRow, MenuRowSkeleton, LoaderSuspense } = MojitoPresentation.Components;
const intentActions = MojitoCore.Intents.actions;

export default function MenuLinkPromotions(props) {
    const dispatch = useDispatch();
    const {
        applicablePage,
        extraLinks,
        onLinkClick,
        onDataLoad,
        mojitoTools: { config, appContext, instanceId },
    } = props;
    const locationId = props.locationId || config.locationId;
    const { routeResolver } = appContext;
    const promotions = usePromotions(locationId, applicablePage);

    const menuItems = useMemo(() => {
        const toMenuItem = menuItemMapper(config, routeResolver, appContext);
        const promotionLinks = promotions.map(toMenuItem);
        const allLinks = config.extraLinksInFront
            ? [extraLinks, promotionLinks]
            : [promotionLinks, extraLinks];

        return allLinks.flat().filter(item => item?.onClickIntent && item?.data);
    }, [config, routeResolver, promotions, extraLinks, appContext]);

    const onItemClick = (id, item) => {
        const { onClickIntent: intent } = item;
        intent && dispatch(intentActions.publishIntent(intent.type, intent.data));
        onLinkClick(item);
    };

    const promotionsLoadDone = usePromotionsLoadDone([locationId]);

    const performanceElementRef = useLoadingStatusMetric(
        promotionsLoadDone,
        config.reportLoadingStatus ? instanceId : undefined,
        'MenuLinkPromotions'
    );

    useEffect(() => {
        promotionsLoadDone && onDataLoad(menuItems);
    }, [promotionsLoadDone, menuItems, onDataLoad]);

    const skeleton = (
        <MenuRowSkeleton config={config.menuRowSkeleton} elementRef={performanceElementRef} />
    );

    return (
        <LoaderSuspense
            config={config.skeletonLoader}
            isContentPending={!promotionsLoadDone}
            loader={skeleton}
        >
            {!isEmpty(menuItems) && (
                <MenuRow
                    config={config.menuRow}
                    menuItems={menuItems}
                    cbItemClicked={onItemClick}
                    elementRef={performanceElementRef}
                />
            )}
        </LoaderSuspense>
    );
}

// ////////////////////////////////////////////////
// ///////// Internal helper functions

const getSportIcon = (sportId, sportIcons) => sportIcons[sportId] || sportIcons.defaultIcon;

const menuItemMapper = (config, routeResolver, appContext) => promotion => {
    const { id, imgUrl, linkText, target = {}, altText } = promotion;
    const { sportId, classId, eventId, typeId, meetingId, type: targetType } = target;
    const onClickIntent = PromotionUtils.resolveIntentFromPromotion(promotion);
    const linkPath = onClickIntent && onClickIntent.data;
    const icon =
        targetType === TARGET_TYPE.SPORT ? getSportIcon(sportId, config.sportIcons) : imgUrl;
    const hrefLink =
        targetType === TARGET_TYPE.EXTERNAL_URL
            ? target.url
            : linkPath && routeResolver.getRoute(linkPath);
    return {
        id,
        icon,
        data: linkText || id,
        hrefLink,
        onClickIntent,
        className: sportId || classId || typeId || meetingId || eventId || TARGET_TYPE.EXTERNAL_URL,
        altText: typeof altText !== 'undefined' ? altText : appContext.sportName(sportId),
    };
};
