import MojitoCore from 'mojito/core';
// import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoApplications from 'mojito/applications';
import {settingsStorage} from 'mojito/core/services/system-settings/slice';

import {currentScriptOrigin, ensureStartingSlash, setInternalResourcesPath} from '#core/utils/url-utils.js';
import {Logger} from '#core/utils/logger.js';
import {merge} from '#core/utils/config-utils.js';
import {generateUniqueId} from '#core/utils/utils.js';
import {getExternalUrlIntent} from '#core/utils/intent-factory.js';
import {DATA_TYPE} from '#core/utils/data-validation.cjs';
import {SpriteMapStorage} from '#core/utils/spritemap-storage/index.js';
import {resolveConfigByCurrentContext} from '#core/utils/context-utils.js';
import {printAutomationStatistics} from '#core/utils/statistics.js';
import {
    checkFontFaceAvailable,
    getCSSFromFontsArray,
    getResettingCSSForContainerId,
} from '#core/utils/embedding-utils.js';

import {pickInitParams} from '#core/api.js';
import customViewsConfig from '#core/config/custom-views-config.js';
import COMMON_FONTS from '#core/config/fonts.yaml';
import {Localization} from '#core/localization.js';
import {DebugFlags} from '#core/application/debug/debugFlags.js';
import {INITIALIZATION_STATE} from '#core/application/types.js';
import {WidgetsProviderService} from '#core/application/widgets-provider/index.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import {allFeatures, registerTypeTransformHandler} from '#core/application/abstract-feature.js';
import {DBXPerformanceService, METRIC} from '#core/services/performance-reporting-service/index.js';
import {AppLoadedNotifier} from '#core/application/application-loaded-notifier.js';

import {BasicPalette} from '#core/application/modules/basic-palette.js';

import {getCommonMojitoConfig} from './application.common-moj-config.js';

const reduxInstance = MojitoCore.Services.redux;
const {dispatch} = MojitoCore.Services.redux.store;
const {actions: SystemSettingsActions} = MojitoCore.Services.SystemSettings;
const AuthenticationActions = MojitoServices.Authentication.actions;
const AuthenticationTypes = MojitoServices.Authentication.types;
const {prefetchDataSuccess} = MojitoServices.Prefetch.actions;
const {selectors: routerSelectors, actions: routerActions} = MojitoApplications.Common.Router;
const IntentActions = MojitoCore.Intents.actions;

const Config = MojitoCore.Services.Config;

function printVersion(log) {
    const STYLE_HEAD = 'color: dodgerblue; font-weight: bold';
    let buildDate = new Date();

    buildDate.setTime(BUILD_TIMESTAMP);
    buildDate = buildDate.toLocaleString('en-GB', {hour12: false});

    log.info(
        `%cVer. ${APP_VERSION}, sha: ${GIT_HASH}` + (process.env.NODE_ENV === 'development' ? ' / DEBUG mode' : ''),
        STYLE_HEAD
    );
    log.info(`%cBuild date: ${buildDate} EEST`, STYLE_HEAD);
}
export {getCommonMojitoConfig} from './application.common-moj-config.js';

function featureAllowed(feature) {
    if (DebugFlags.no3rdParties || WidgetsProviderService.isFullscreenWidget()) {
        if (feature.isThirdParty) return false;
    }

    return true;
}

function restoreSessionIfLost({payload: reason}) {
    if (reason === AuthenticationTypes.LOGOUT_REASON.UNEXPECTED_SESSION_LOST) {
        dispatch(AuthenticationActions.restoreSession());
    }
}
function setDefaultConfigValues(configs = {}) {
    for (const configId in configs) {
        const configValue = configs[configId];

        Config.configRegistry.add({
            ...configValue,
            name: configId,
        });
    }
}

function printDebugInfo(log, mojitoConfig) {
    log.group('%cDebug info', Logger.STYLE_DEBUG);
    window.DBX.statistics = printAutomationStatistics(mojitoConfig.services.configurations);
    log.groupEnd();
}

const supplementaryContainerId = `${PRODUCT_NAME}-supplementary`;

function mountSupplementary(configuration) {
    if (document.getElementById(supplementaryContainerId)) {
        return;
    }

    const fonts = COMMON_FONTS.concat(configuration?.fonts || []);
    let notLoadedFonts = [];

    for (let i = 0; i < fonts.length; i++) {
        if (!checkFontFaceAvailable(fonts[i].family)) {
            notLoadedFonts.push(fonts[i]);
        }
    }

    const supplementalEl = document.createElement('DIV');
    supplementalEl.id = supplementaryContainerId;

    SpriteMapStorage.init(
        supplementalEl,
        configuration?.customSportIconsMapping || {},
        ApplicationConfig.initParams.postInitConfig
    );

    if (notLoadedFonts.length > 0) {
        const style = document.createElement('style');
        style.id = `${supplementalEl.id}-font-styles`;
        style.innerHTML = getCSSFromFontsArray(notLoadedFonts);
        supplementalEl.appendChild(style);
    }

    document.body.appendChild(supplementalEl);
}

export function unmountSupplementary(log) {
    const el = document.getElementById(supplementaryContainerId);
    if (!el) {
        log.error('Cannot unmount supplementary');
        return;
    }
    el.parentNode.removeChild(el);

    SpriteMapStorage.dispose();
}

function forwardMetrics() {
    performance.measure(`dbx:${PRODUCT_NAME}:init`, 'dbx:init:start', 'dbx:init:end');
    DBXPerformanceService.reportSystemMeasurementStartTime('dbx:init:start', METRIC.INIT_START);
    DBXPerformanceService.reportSystemMeasurementDuration(`dbx:${PRODUCT_NAME}:init`, METRIC.INIT_DURATION);
}

const {INIT_NONE, INIT_INITIALIZING, INIT_DONE} = INITIALIZATION_STATE;

export function init({log, state, _initParams, mojitoBootstrap, getMojitoConfigFn, applicationModulesPromise}) {
    if (state.initializationState !== INIT_NONE) {
        log.error(`${PRODUCT_NAME} is already initialized. Ignoring "init" call...`);
        return state.initializationPromise;
    }

    performance.mark('dbx:init:start');

    try {
        printVersion(log);

        state.initializationState = INIT_INITIALIZING;
        state.uid = generateUniqueId(); // will be required and referenced later
        state.overlayId = `overlays-${state.uid}`;
        try {
            ApplicationConfig.updateInitParams(pickInitParams(_initParams));
        } catch (e) {
            return Promise.reject(e.message);
        }

        registerTypeTransformHandler(DATA_TYPE.INTENT, getExternalUrlIntent);
        const initParams = ApplicationConfig.initParams;
        const bundledAppConfig = ApplicationConfig.bundledConfig;
        const externalAppConfig = _initParams.configuration || {};

        bundledAppConfig.assetsLocation && setInternalResourcesPath(bundledAppConfig.assetsLocation);

        const additionalContext = initParams.uiContext || settingsStorage.getAdditionalContextFromUrl() || '';
        dispatch(SystemSettingsActions.updateAdditionalContext({additionalContext})); // Configure it prior mojito

        // --- Init perf service
        DBXPerformanceService.initOnce(initParams.routingPrefix);

        return new Promise((success, fail) => {
            log.info('%c[1/7] Loading external config and translations...', Logger.STYLE_PROGRESS);

            const translationsPromise = Localization.init(initParams.language);
            const configPromise = ApplicationConfig.init(bundledAppConfig, externalAppConfig, {
                configNameToLoad: initParams.configNameToLoad,
            });

            Promise.all([configPromise, translationsPromise, applicationModulesPromise])
                .then(() => {
                    performance.mark('dbx:config-loaded');
                    if (ApplicationConfig.configAccessViolated) return;

                    Localization.configure(
                        ApplicationConfig.config.mojitoContentLocales,
                        ApplicationConfig.config.translations
                    );

                    // --- print some info
                    ApplicationConfig.apiUrl !== currentScriptOrigin &&
                        log.debug(`%cAPI url is set to ${ApplicationConfig.apiUrl}`, Logger.STYLE_DEBUG);
                    ApplicationConfig.assetsLocation !== currentScriptOrigin + '/' &&
                        log.info(`%cAssets location is set to ${ApplicationConfig.assetsLocation}`, Logger.STYLE_DEBUG);

                    log.info(`%cCurrent language is "${Localization.getCurrentLanguage()}"`, Logger.STYLE_DEBUG);
                    // ---

                    reduxInstance.actionListener.startListening({
                        actionCreator: AuthenticationActions.disposeSession,
                        effect: restoreSessionIfLost,
                    });

                    if (DebugFlags.analyzeConfigs) ApplicationConfig.analyzeConfigs();

                    allFeatures.setupConfigs(
                        resolveConfigByCurrentContext(ApplicationConfig.config),
                        initParams,
                        featureAllowed
                    );

                    allFeatures.cookieConsentRestrictionsInit();

                    mountSupplementary(ApplicationConfig.config);

                    log.info('%c[2/7] Loading external dependencies...', Logger.STYLE_PROGRESS);

                    allFeatures
                        .beforeMojito()
                        .then(() => {
                            log.info('%c[3/7] Preparing tokens...', Logger.STYLE_PROGRESS);
                            BasicPalette.load(ApplicationConfig.config);

                            // Split execution flow to small tasks to avoid blocking
                            // of user interaction (hehe) - TBT metric in Lighthouse.
                            // In other words this is needed to make Lighthouse happy :)
                            setTimeout(() => {
                                try {
                                    log.info('%c[4/7] Building mojito configs...', Logger.STYLE_PROGRESS);
                                    let mojitoConfig = {};
                                    allFeatures.beforeMojitoConfigBuild(mojitoConfig);

                                    mojitoConfig = merge(
                                        mojitoConfig,

                                        getCommonMojitoConfig({
                                            noAuthentication:
                                                WidgetsProviderService.getWidgetData().noAuthenticationService,
                                            overlayRandomId: state.overlayId,
                                        }),
                                        getMojitoConfigFn && getMojitoConfigFn(ApplicationConfig.config),
                                        ApplicationConfig.mojitoConfiguration
                                    );

                                    allFeatures.afterMojitoConfigBuild(mojitoConfig);
                                    const mojDefConfigsStart = performance.now();
                                    setDefaultConfigValues(customViewsConfig);
                                    performance.measure('dbx:mojito-set-default-configs', {start: mojDefConfigsStart});

                                    window.DBX = {
                                        ...window.DBX,
                                        mojitoConfig,
                                        spriteMapStorage: SpriteMapStorage,
                                        appConfig: ApplicationConfig.config,
                                        basicPalette: BasicPalette.palette,
                                        allFeatures: allFeatures,
                                        publishIntent(intentType, intentData) {
                                            dispatch(IntentActions.publishIntent(intentType, intentData));
                                        },
                                        parseAndPublishIntent(intentStr) {
                                            const intent = getExternalUrlIntent(intentStr);
                                            this.publishIntent(intent.type, intent.data);
                                        },
                                    };
                                    if (process.env.NODE_ENV === 'development') {
                                        printDebugInfo(log, mojitoConfig);
                                    }
                                    // Make Lighthouse happy again :)
                                    setTimeout(() => {
                                        try {
                                            const mojitoStart = performance.now();
                                            log.info('%c[5/7] Starting up mojito...', Logger.STYLE_PROGRESS);

                                            mojitoBootstrap.init(mojitoConfig);

                                            performance.measure('dbx:init-mojito', {start: mojitoStart});
                                            log.info('%c[6/7] After mojito...', Logger.STYLE_PROGRESS);
                                            allFeatures
                                                .afterMojito()
                                                .then(() => {
                                                    log.info('%c[7/7] Done.', Logger.STYLE_PROGRESS);
                                                })
                                                .then(() => {
                                                    state.initializationState = INIT_DONE;
                                                    performance.mark('dbx:init:end');
                                                    forwardMetrics();

                                                    setTimeout(success);
                                                });
                                        } catch (err) {
                                            log.error('Failed to init Mojito.', err);
                                            fail(err);
                                        }
                                    });
                                } catch (err) {
                                    log.error('Failed to build configs.', err);
                                    fail(err);
                                }
                            });
                        })
                        .catch(error => {
                            log.error('Failure (2).', error);
                            fail(error);
                        });
                })
                .catch(error => {
                    log.error('Failure (1).', error);
                    fail(error);
                });
        });
    } catch (e) {
        log.error(`Initialization failed.`, e);
        performance.mark('dbx:init:end');
        forwardMetrics();
        state.initializationState = INIT_NONE;
        return Promise.reject(e);
    }
}

export function terminate({state, log, mojitoBootstrap}) {
    try {
        if (state.containerNode) {
            unmount({log, state, mojitoBootstrap});
        }
        WidgetsProviderService.unmountAllWidgets();
        unmountSupplementary(log);

        reduxInstance.actionListener.stopListening({
            actionCreator: AuthenticationActions.disposeSession,
            effect: restoreSessionIfLost,
        });

        allFeatures.dispose();
        mojitoBootstrap.terminate();
        state.initializationState = INIT_NONE;
        state.initializationPromise = null;
        return true;
    } catch (e) {
        state.initializationState = INIT_NONE;
        state.initializationPromise = null;
        log.error(e);
        return false;
    }
}

export function mount(containerId, params = {}, {log, state, mojitoBootstrap, onLoaded}) {
    let initParams = ApplicationConfig.initParams;

    if (state.initializationState !== INIT_DONE) {
        log.error(`Cannot mount because ${PRODUCT_NAME} is not initialized. Please call .init() first`);
        return false;
    }
    if (!containerId) {
        containerId = initParams.containerId;
        if (!containerId) {
            log.error('Cannot mount because container ID is not specified');
            return false;
        }
    }
    if (state.containerNode && state.containerNode.id === containerId) {
        // Already mounted at the same node. Ignoring
        return true;
    }

    const containerEl = document.getElementById(containerId);
    if (!containerEl) {
        log.error(`Cannot mount ${PRODUCT_NAME}. No DOM node found with provided id: ${containerId}`);
        return false;
    }

    if (window.$MOJITO_PREFETCH) {
        dispatch(prefetchDataSuccess(window.$MOJITO_PREFETCH));
        delete window.$MOJITO_PREFETCH;
    }

    initParams = ApplicationConfig.updateInitParams({containerId});
    try {
        if (params.routingPrefix) {
            initParams = ApplicationConfig.updateInitParams({
                routingPrefix: ensureStartingSlash(params.routingPrefix),
            });
            routerSelectors.getRouteResolver().setRoot(initParams.routingPrefix);
        }

        if (state.containerNode) {
            // Already mounted at different container
            // Need to transfer from old node to new containerId

            // Find all swiper elements and save their scroll options
            const swipers = document.getElementsByClassName('ta-Swiper');
            const scrollOptions = [...swipers].map(({scrollLeft, scrollRight}) => {
                return {scrollLeft, scrollRight};
            });

            state.containerNode.removeChild(state.reactAppNode);
            state.stylesNode && state.containerNode.removeChild(state.stylesNode);
            state.overlayContainerNode.removeChild(state.overlayNode);

            containerEl.innerHTML = ''; // Clear all contents
            state.stylesNode && containerEl.appendChild(state.stylesNode);
            state.overlayContainerNode =
                (initParams.overlayContainerId && document.getElementById(initParams.overlayContainerId)) ||
                containerEl;
            state.overlayContainerNode.appendChild(state.overlayNode);
            containerEl.appendChild(state.reactAppNode);

            // Find all swiper elements and restore their scroll options
            const newSwipers = document.getElementsByClassName('ta-Swiper');
            [...newSwipers].forEach((swiper, index) => {
                swiper.scrollLeft = scrollOptions[index].scrollLeft;
                swiper.scrollRight = scrollOptions[index].scrollRight;
            });

            state.containerNode = containerEl;
            return true;
        }

        const uid = state.uid;
        const reactAppId = `${PRODUCT_NAME}-${uid}`;
        allFeatures.beforeMount(containerEl);

        containerEl.innerHTML = ''; // Clear all contents

        if (ApplicationConfig.isEmbedded) {
            const style = (state.stylesNode = document.createElement('style'));
            style.id = `reset-styles-${uid}`;
            style.innerHTML = getResettingCSSForContainerId(reactAppId);
            containerEl.appendChild(style);
        } else {
            state.stylesNode = null;
        }

        let reactAppNode = (state.reactAppNode = document.createElement('div'));
        reactAppNode.id = reactAppId;
        if (ApplicationConfig.isEmbedded) {
            reactAppNode.style.position = 'relative';
            reactAppNode.style.zIndex = initParams.applicationPlaneZIndex;
        }
        containerEl.appendChild(reactAppNode);

        state.overlayContainerNode =
            (initParams.overlayContainerId && document.getElementById(initParams.overlayContainerId)) || containerEl;

        let overlay = (state.overlayNode = document.createElement('div'));
        overlay.id = state.overlayId;
        overlay.classList.add('notranslate'); //DBX-10930: Disabled Google Translate on overlays (including mobile betslip)
        state.overlayContainerNode.appendChild(overlay);

        // ----  Adjust DOM
        const start = performance.now();
        DBXPerformanceService.reportTiming(METRIC.RENDER_START, start);
        mojitoBootstrap.render(reactAppNode.id);
        state.containerNode = containerEl;
        performance.measure('dbx:mojito-render', {start});
        allFeatures.afterMount(containerEl);

        DBXPerformanceService.addLogger(new AppLoadedNotifier(onLoaded)); // it will remove itself after 30 sec
        return true;
    } catch (e) {
        log.error(e);
        return false;
    }
}

export function unmount({log, state, mojitoBootstrap}) {
    try {
        if (!state.containerNode) {
            log.warn(`Trying to unmount '${PRODUCT_NAME}' that was not mounted. Ignored`);
            return false;
        }
        allFeatures.beforeUnmount();
        mojitoBootstrap.unmount();
        state.containerNode.innerHTML = '';
        state.containerNode = null;
        state.reactAppNode = null;
        state.overlayContainerNode = null;
        state.overlayNode = null;
        state.stylesNode = null;
        dispatch(routerActions.reset()); // TODO temporary workaround for old Portal on HollandCasino, see details at DBX-12447
        return true;
    } catch (e) {
        log.error(e);
        return false;
    }
}
