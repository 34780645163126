/* eslint no-unused-vars: "off" */
import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {COLOR, FONT} from '../theme.js';

export function getOnboardingConfig() {
    return {
        palette: {},
    };
}
